import * as React from 'react';
import {PositionComponent} from '../../components/pages/careers/_PositionComponent';

export default () => <PositionComponent
    title={'Senior Platform Backend Engineer (Java)'}
    aboutPositionTitle={'The Platform'}
    aboutPosition={`Our platform empowers people to create sophisticated data visualization and analysis with the simplicity of PowerPoint and Excel.
                    The in-house spreadsheet engine and UI runs in a browser and allows data scientists and digital artisans to create elaborate
                    stories around their data.`}
    aboutRole={`You will be involved in all the aspects of a product development starting from a feature estimation, continuing
                with the architecture design and implementation of key backend services that drive the user facing experience for millions
                of future data scientists, analysts and business users.`}
    aboutYou={  [
        `Strong CS background`,
        `Java 8`,
        `Experience with distributed systems`,
        `Good English level and communication skills`
    ]}
/>;
